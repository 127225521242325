//
// Close icons
// --------------------------------------------------


.close {
  float: right;
  font-size: (@font-size-base * 1.5);
  font-weight: @close-font-weight;
  line-height: 1;
  color: var(--light);
  text-shadow: var(--dark);
  //.opacity(.2);
  text-decoration: none;
  background: @brand-gradient;
  padding: .25rem .5rem 0;
  border-radius: 8px;

  &:hover,
  &:focus {
    color: var(--light);
    text-decoration: none;
    cursor: pointer;
    //.opacity(.5);
    //background: @brand-primary;
    transform: scale(110%) rotate(10deg);
    //transition: opacity 0.3s ease-in-out; /* Smooth transition for the gradient */
    box-shadow: 0 0 1px 1px rgba(255,255,255, 0.5),
    0 0 2px 2px rgba(0,0,0, 0.2);
  }

  // Additional properties for button version
  // iOS requires the button element instead of an anchor tag.
  // If you want the anchor version, it requires `href="#"`.
  // See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile
  button& {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
  }
}
